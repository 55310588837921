.prompt-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0px;
  margin: 0px;
  width: 98vw;
}
.input-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.flex-item {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin: 0px;
  padding: 0px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}
.autosize-textarea {
  font-size: 13px;
  min-width: 98% !important;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.prompt-variables {
  display: flex;
  flex-wrap: wrap;
  gap: 0px; /* space between items */
}

.prompt-variables input > div {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 0; /* space between label and input */
}
.description-text {
  font-size: 13px;
  line-height: 1.2;
}
.prompt-variables > div > label {
  font-size: 12px;
}
.template-selector,
.subcategory-selector,
.category-selector {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  margin: 2px 0 0 0;
  background-color: #fff5e0;

  gap: 0;
  border: 0.1px solid black;
  border-radius: 6px;
  flex-grow: 0;
}

.input-controls {
  width: 100%;
  padding: 0;
}

.input-controls label {
  min-width: 100px;
  text-align: right;
  display: inline-block;
}

.row:nth-child(even) {
  background-color: #d8d8d8;
}

.title-n-speak {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.insert-from-clipboard {
  background-color: #ff9900;
}

.flex-item,
.label,
.checkbox {
  padding: 0px;
  margin: 0px;
}

.prompt-container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 0;
  margin: 0;
  height: 16x;
}
.label {
  font-weight: bold;
}

.flex-item.row.active {
  background-color: rgb(234, 255, 228); /* your desired color */
}

.info-flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.custom-file-input {
  display: none;
}

.custom-file-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 2px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  height: 28px;
}
.border-around-upload {
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.top-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  margin: 0;
}

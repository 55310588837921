/* UPLOAD FILE */
.wide label {
  display: flex;
  align-items: center;
}
.wide input[type="checkbox"] {
  margin-left: 8px;
}

/* Hide the actual input element */
.custom-file-input {
  display: none;
  width: 180px;
}

/* ==== */
.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
}

/*  */
.toggleInputsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.expert-input-flex {
  display: flex;
  flex-direction: column;
  background-color: #fff9c4;
  width: 100%;
}
#toggleInputs {
  margin-left: 5px;
  text-align: right;
  width: 20px;
}
#checkbox-show-hide {
  margin: 0px;
  text-align: left;
}

.column1,
.column2,
.column3,
.add_buttons {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.column1 {
  width: 200px;
}

.column2 {
  width: 70%;
}

.column3 {
  width: 200px;
}
.buttons {
  width: 98%;
}

.add_buttons {
  width: 100px;
}
.column2 textarea {
  width: 98%;
}
.input-container {
  padding: 0;
  margin-bottom: 0px;
}

/* TABS PORTION AT TOP OF PAGE */
.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 0px;
  background-color: #bfbfb0;
}
.tabs button {
  background-color: #020202;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
}
.tabs button:hover {
  background-color: #b9dbff;
}
.tab-content {
  padding: 0px;
  background-color: #e6e6e6;
  border: 1px solid #ddd;
}

/* VALUES TABLE ==================================== 
====================================================*/
.row {
  display: flex;
}

.description_and_prompt {
  width: 100%;
}

.info {
  display: inline-block;
  width: 80px;
  margin: 0px;
  font-size: 14px;
}

.input-table-column3 {
  display: flex;
  flex-direction: column;
}
.alternating-rows .row:nth-child(even) {
  background-color: #e0e0e0; /* Light gray for even rows */
}

.alternating-rows .row:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

@media (min-width: 1000px) {
  .table-container {
    overflow-x: scroll;
  }
}

/* DELETE ALL BUTTON */
.deleteAll {
  background-color: red;
  color: #020202;
  text-transform: uppercase;
  border: none;
  height: 26px;
}
.deleteAll:hover {
  background-color: black;
  color: red;
  text-transform: uppercase;
  border: none;
}

.table-data-heading {
  display: flex;
  justify-content: space-between;
}
/* CSS FOR UPLOAD PORTION */
.file-input-container {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-right: 30px;
}

.file-input-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #4da150;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.file-input-button:hover {
  background-color: #ddd;
}

#search {
  width: 120px;
}
.box-upload-file {
  border: 1px solid #333;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.checkbox-div {
  width: 50px;
}
.button-over-file-imput {
  width: 150px;
}
.checkbox {
  height: 100px;
}

.information-input-flex-table {
  display: flex;
  flex-direction: column;
}
.category-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.top-row-search-sort-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.table-data-heading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.check_show_hide_form {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  border-radius: 7px;
  margin: 0 0 0 30px;
  padding: 14px;
}
.top-line {
  padding: 0;
  margin: 0;
}
.expert_download_box {
  border: 1px solid black;
  border-radius: 8px;
}
.sort_select {
  height: 28px;
  width: 140px;
}

.input_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 1 solid gray;
}

.label_input_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  width: 300px;
  padding: 0;
}
.label_input_wrapper label {
  width: 100px;

  text-align: right;
}

.prompts_row_record {
  display: flex;
  flex-direction: row;
}

.multi_label_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
}
.delete_button {
  background-color: red;
}
.table_row {
  display: flex;
  flex-direction: column;
  border: #333 1px solid;
  margin: 0;
}
.unused_niche_buttons {
  display: flex;
  flex-direction: row;
  border: #333 1px solid;
  align-items: center;
  border-radius: 6px;
  padding-left: 10px;
}
.used_niche_buttons {
  display: flex;
  flex-direction: row;
  border: #333 1px solid;
  align-items: center;
  border-radius: 6px;
  padding-left: 10px;
}
.slider_niches {
  display: flex;
  align-items: center;
}

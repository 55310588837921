.user-prompts {
  width: 97%;
  height: 100%;
  /* top: 55px; */
  position: fixed;
}
.prompt-textarea textarea {
  width: 98%;
  margin: 2px 0;
}
.user-prompts {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* this will make all items full-width */
}

.prompt-input {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.prompt-input input {
  flex: 1; /* this will make all inputs equally wide */
  margin: 2px; /* to put some space around inputs */
}
.on-top {
  width: 99%;
}

.records-table {
  display: none;
}

.input_add {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.button_containers {
  display: grid;
}
.inline_row {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid red;
  margin: 0;
}
.button-text {
  width: calc(100% - 200px);
}

.edit_button {
  background-color: yellow;
  color: black;
}
.area_select {
  width: 200px;
  height: 30px;
}
.text_input_area {
  width: 1000px;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  width: 50%;
  margin: auto;
}
.username {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

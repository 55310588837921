.App {
  width: 98vw;
  height: 99vh;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}
html {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -1.5px;
  top: -1.5px;
}
body {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -1.5px;
  top: -1.5px;
  overflow-y: auto;
  overflow-x: auto;
  /* background-color: white; */
  background: #efefef;
  background: -moz-linear-gradient(-45deg, #efefef 0%, #a8a8a8 100%);
  background: -webkit-linear-gradient(-45deg, #efefef 0%, #a8a8a8 100%);
  background: linear-gradient(135deg, #efefef 0%, #a8a8a8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#efefef',
      endColorstr='#a8a8a8',
      GradientType=1
    );
}

/* App-wide styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  padding: 6px;
  border: none;
  border-radius: 4px;
  border: 0.2px solid black;
  width: 200px;
  font-family: monospace;
}
select {
  padding: 6px;
  border: 0.2px solid black;
  border-radius: 4px;
  width: 200px;
  font-size: 12px;
  font-family: monospace;
}
input {
  padding: 4px;
  border: 0.2px solid black;
  border-radius: 4px;
  font-size: 13px;
  font-family: monospace;
  margin: 0;
}
label {
  font-size: 12px;
  font-weight: 600;
  font-family: monospace;
  padding: 4px;
}
button {
  background-color: #4caf50;
  color: white;
  border: none; /* border: .2px solid #4caf50; */
  padding: 6px;
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
}
button:hover {
  background-color: #3e8e41;
}

button:active {
  background-color: #4caf50;
}

h1 {
  font-size: 18px;
  font-weight: 700;
}
h2 {
  font-weight: 500;
}
p {
  margin-bottom: 10px;
}
h1,
h2,
h3 {
  margin: 5px 0;
}
div {
  padding: 3px;
}
label {
  display: block;
  color: black;
  margin: 5px;
}
input {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  width: 150px;
}
.main-container {
  margin: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Main content styles */
main {
  padding: 0;
}

.flexRowWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.flexRow,
.flexRowNoWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.red {
  background-color: red;
  color: white;
}

.width100 {
  width: 100%;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.borderAround {
  margin-top: 4px;
  border: 1px solid black;
  border-radius: 6px;
  margin: 3px;
  padding: 4px;
}

.spacer {
  margin-right: 10px;
}

.setWidth150 {
  width: 150px;
}
strong {
  font-weight: bold;
}
.wide {
  width: 500px;
}
.marginRight10px {
  margin-right: 10px;
}
.margin_right {
  margin-right: 30px;
}

/* START OF SWITCH SLIDER */
.switch {
  position: relative;
  display: inline-block;
  min-width: 22px;
  height: 10px;
  /* border: rgb(45, 45, 45) 0.1px solid; */

  /* border-radius: 10px; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #aaa;
  transition: 0.4s;
  border-radius: 10px;
  /* box-shadow: inset 0 0 4px #3e58ff; */
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(10px);
}

/* END OF SWITCHSLIDER */
.speak-button {
  padding: 3px 6px;
  background-color: #007bff;
  cursor: pointer;
  border-radius: 2px;
  font-size: 12px;
  margin: 0 3px;
}

.download {
  background-color: #fff27c;
  color: black;
}

.delete {
  background-color: red;
  color: white;
  font-weight: 600;
}
.fullWidth {
  width: 100%;
  /* justify-content: space-between; */
}

.width400 {
  width: 400px;
}
.borderBottom {
  border-bottom: 4px solid black;
}

.padding0 {
  padding: 0px;
}
.margin0 {
  margin: 0px;
}

/* THE TOP INPUT FORM CSS =============================================
=======================================================================*/
.information-input-form {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff9c4;
  width: 100%;
  /* min-width: 600px; */
  overflow-x: auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.input-label {
  margin-bottom: 0px;
}
.input-field {
  margin-bottom: 0px;
}

.order-add-button {
  display: flex;
  flex-direction: row;
  width: 180px;
  justify-content: space-evenly;
}

.input_description {
  width: 98%;
}

.input-container {
  display: flex;
  align-items: center;
}

.prompt-input-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.prompt-input-description textarea {
  width: 98%;
  margin: 5px 5px;
}

.input-field {
  margin: 0;
  padding: 3px;
}

.input-container .Info {
  padding: 0;
  margin: 0;
  width: 150px;
}
.Info {
  margin: 0;
  padding: 0;
}
.flex-table-row {
  display: flex;
  flex-wrap: wrap;
  min-width: 600px;
}
.flex-table-cell {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}

.tbl-col1 {
  /* flex-basis: 20%; */
  width: 400px;
}
.tbl-col2 {
  flex-basis: 69%;
}
.tbl-col3 {
  /* flex-basis: 5%; */
  max-width: 60px;
}

.table_description {
  width: 98%;
}

.sort_diff_add_col_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}
.info_two_columns {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 20px;
}
.info_input_col_1 {
  display: flex;
  flex-direction: column;
  width: 180px;
  padding: 0;
}
.info_col_3 {
  padding: 10px 20px;
  flex-grow: 1;
}

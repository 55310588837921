/* TABS PORTION AT TOP OF PAGE */
.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 0px;
  background-color: #bfbfb0;
}
.tabs button {
  background-color: #020202;
  border: none;
  cursor: pointer;
  padding: 4px 5px 4px 5px !important;
  transition: background-color 0.3s;
  height: 30px;
  font-size: 12px;
}
.tabs button:hover {
  background-color: #007bff;
}
.tab-content {
  padding: 0px;
  background-color: #e6e6e6;
  border: 1px solid #ddd;
}
.takeTheRemainingSpace {
  flex: 1;
  /* background-color: red; */
  /* width: 90%; */
}
.qp_select {
  width: 150px;
  margin-right: 10px;
}
.edit_check_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.vbutton.active {
  background-color: #007bff; /* Active state background color */
  color: white; /* Active state text color */
}

.vbutton.inactive {
  background-color: #f8f9fa; /* Inactive state background color */
  color: black; /* Inactive state text color */
}
.fancy_text {
  padding: 10px 30px;
  font-size: 16px;
  line-height: 1.5;
}

.selectedTab {
  background-color: #006400;
  border: 1px solid #006400;
}
.selectedTab:hover {
  background-color: #006400;
  border: 1px solid #006400;
}
html,
body,
#root,
.appWindowsContainer {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flexRowNoWrap {
  display: flex;
  flex-wrap: nowrap;
}

.section {
  flex: 1;
  padding: 0;
  margin: 0;
  /* background-color: green; */
}

.flexRowNoWrap {
  padding: 0 !important;
  margin-top: 2px;
  /* background-color: pink; */
}

.noSpace {
  padding: 0;
  margin: 0;
}

.hub-page-container {
  font-family: "Arial, sans-serif";
  text-align: center;
  /* padding: 20px; */
  width: 100vw;
  height: 100vh;
  position: relative;
}

.hub-page-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

/* .hub-page-container img {
  width: 100%;
  max-width: 100px;
  height: auto;
  margin: 10px;
} */

.hub-page-container .text-chunk {
  margin: 20px 0;
  font-size: 1.2em;
  line-height: 1.6;
}

/* .hub-page-container .buttons-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
} */

.hub-page-container .buttons-group a {
  text-decoration: none;
}

.hub_buttons {
  /* display: flex; */
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: #0069d9;
  color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

body {
  padding: 25px;
}

body .hub-page-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body .hub-page-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("https://source.unsplash.com/featured/?nature,castle");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.hub-content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  /* backdrop-filter: blur(2px); */
  padding: 0px;
}

.hub-content .top-image {
  width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.8);
  border-radius: 50px;
  background-color: white;
  margin-top: 20px;
}

.hub-content .top-image:hover {
  transform: scale(1.15);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

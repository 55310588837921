.ChatGPT_helper {
  margin: 0;
  /* overflow: auto; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.div-rows {
  width: 1500px !important;
  flex-direction: column;
  flex-wrap: wrap;
}

input[type="number"] {
  width: 45px;
}

.flex-table-row-top {
  display: flex;
  align-items: center;
  gap: 3px;
  width: 100%;
}
.flex-table-row-bottom {
  display: flex;
  align-items: center;
  gap: 3px;
}

.flex-table-row-wrapper:nth-child(even) {
  background-color: #ffffff;
  border-top: solid 3px black;
  width: 100%;
}

.flex-table-row-wrapper:nth-child(odd) {
  border-top: solid 3px black;
  background-color: #e2e2e2;
  width: 100%;
}

.flex-table-row-wrapper.selected {
  width: 1200px !important;
  background-color: bisque;
}
.flex-table-row-wrapper {
  width: 1200px !important;
}
.checkbox-span {
  font-size: 12px;
  margin-right: 5px;
  color: Blue;
}
input.checkbox {
  margin-left: 20px;
}

input {
  margin-right: 5px !important;
}

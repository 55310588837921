.niche-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.niche-rows button {
  height: 26px;
}

.inline-row {
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-radius: 6px;
  margin-right: 5px;
  width: 270px;
  align-items: center;
}

.categories {
  /* display: flex;
  flex-direction: row; */
  /* background-color: lightcyan; */
  border: 2px solid yellow;
  border-radius: 6px;
  margin-right: 10px;
  flex-grow: 0;
  align-items: center;
  /* flex-wrap: wrap; */
}

.niches {
  display: flex;
  flex-direction: column;
}

.niche-names {
  width: 160px;
}

.add_categories {
  /* background-color: orange; */
  border: 2px solid green;
  border-radius: 6px;
}

.header {
  background-color: #202123;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: row;
  padding: 1rem;
}

.footer {
  background-color: #202123;
  color: white;
  padding: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.submenu {
  padding-top: 8px;
  border-top: 1px solid #ddd;
}

.submenu span {
  font-weight: bold;
  color: #333;
  padding: 8px;
}

.submenu a {
  display: block;
  padding: 8px;
  color: #333;
  text-decoration: none;
}

.submenu a:hover {
  background-color: #f2f2f2;
}

nav a {
  margin: 10px 10px;
  text-decoration: none;
  color: #fffdfd;
}

nav a:hover {
  color: white;
  font-weight: bold;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 1px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn {
  cursor: pointer;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
  width: auto;
  white-space: nowrap;
  left: 100px;
  top: 100%;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: #202123;
}

html,
body {
  height: 100vh;
  width: 100vw;
  user-select: none;
  font-size: 16px;
  /* overflow: hidden !important; */
}

.category-list {
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  width: 95px;
  overflow-y: auto;
  background-color: #f0f0f0;
  border-right: 1px solid #ccc;
  padding: 0;
  margin: 0 0;
  font-size: 13px;
  font-weight: 600;
  z-index: 1;
}

.category-list div {
  padding: 5px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  /* border-radius: 5px; */
}

.category-list div:hover {
  background-color: gray;
  color: white;
}

.category-list .selected {
  background-color: gray;
  color: white;
}

.text-blob {
  background-color: #fff5e0;
  padding: 8px;
  border: 0.25px solid gray;
  border-radius: 10px;
  line-height: 1.25;
  position: fixed;
  top: 50px;
  left: 110px;
  margin: 0 5px 0 0;
  cursor: pointer;
  flex-grow: 1;
  /* min-width: 280px; */
  width: 85%;
  z-index: 1;
  height: calc(100% - 110px);
  overflow: auto;
}

.ztitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
}
.flex-container {
  display: flex;
  flex-direction: row;
}

.userControls {
  position: fixed;
  bottom: 0;
  left: 90px;
  z-index: 3;
  /* overflow: hidden; */
}

.div-button-Images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.checkbox {
  height: 20px;
  width: 30px;
  margin: 0;
  padding: 0;
}

.div-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.reset {
  background-color: red;
}

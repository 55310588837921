.evenRow {
  background-color: #f7f7f7;
}
.oddRow {
  background-color: #e0e0e0;
}
.activeRow {
  background-color: #fcec93;
}

.about_you,
.response {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 450px;
}

.character_count {
  background-color: #fce250;
  padding: 6px;
  border-radius: 6px;
}
.space_between {
  gap: 20px;
}
.title {
  width: 500px;
  font-size: 12px;
  margin: 4px;
  padding: 5px;
}

.accordion {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
}

.accordion-title:hover {
  background-color: #e5e5e5;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.accordion-content {
  padding: 10px;
  border-top: 1px solid #ccc;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  background-color: #fff9cc;
  display: flex;
  flex-direction: column;
}

.accordion-title {
  background-color: black;
  border: 1px solid black;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}
.accordion-title > :nth-child(2) {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Optional: adds some space between the buttons */
}

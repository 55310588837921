.label_input {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.label_input label {
  width: 65px;
  text-align: right;
  vertical-align: middle;
}
.record-even {
  background-color: #fff8cd;
  padding: 0;
  margin: 0;
}
.record-odd {
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}
.category {
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}
.autosize-textarea {
  font-size: 13px;
  width: calc(100% - 200px);
  min-width: 600px;
  margin: 6px;
  margin: 0;
  border: 1px solid black;
  border-radius: 4px;
}

/* .my_prompt_header {
  display: flex;
  flex-direction: row;
} */
/* .button {
  margin: 0;
  text-align: start;
} */

/* .done_button {
  width: 300px;
  border: 1px solid black;
} */

/* .center_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
} */

/* .prompt_description {
  padding: 10px;
  flex-grow: 1;
} */
/* .edit_check_button {
  display: flex;
  flex-direction: row;
  align-items: center;
} */
/* .local_buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
} */
/* .selected_record {
  display: flex;
  flex-direction: row;
  background-color: rgb(198, 198, 198);
  flex-wrap: nowrap;
} */

/* .prompt_columns {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 260px;
} */

/* .textarea {
  width: 98%;
  margin-bottom: 10px;
} */
